import { NgModule, APP_INITIALIZER } from '@angular/core';
import { PathLocationStrategy, Location, CommonModule } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/*
 * Platform and Environment providers/directives/pipes
 */
import { ROUTES } from './app.routes';
// App is our top level component
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';
import { AppState, InternalStateType } from './app.service';
import { BarcodeComponent } from './barcode/barcode.component';
import { LoginComponent, UserPassComponent, InstitutionComponent, SsoComponent, FindInstitutionComponent, GoogleLoginComponent } from './login';
import { AppDownComponent, ErrorGuard } from './app-down';
import { SharedModule } from './_shared/shared.module';
import { SiteHeaderModule } from './site-header/site.header.module';
import { UpdatePathComponent, UpdatePathGuardService } from './update-path';

import {
  SiteFooterComponent,
  SiteBrandingComponent,
  ModalModule,
  NotificationModule
} from './_globals';
import { NoContentComponent } from './no-content';

import {
  ConfigService,
  SessionService,
  AuthService,
  SeoService,
  UiProfileService,
  ProductService,
  TokenService,
  MicrosoftAuthService,
  TermService,
  TopicService,
  GoogleAnalyticsService,
  CleverService,
  FaviconsService,
  BrowserFavicons,
  BROWSER_FAVICONS_CONFIG,
  URLService
} from './_services';

import {
  AuthGuardService,
  ClickOutsideDirective,
  QueryString,
  BootstrapProduct,
  ProductCheck,
  ProductConfig
} from './_utilities';

import { AuthCheckGuardService } from './auth-check/auth.check.guard.service';

import '../../bower_components/velocity/velocity.js';
import 'hls.js/dist/hls.js';

import { Cookie } from 'ng2-cookies/ng2-cookies';
import { ShibbolethService } from './_services/shibboleth/shibboleth.service';
import { GoogleAuthentificationService } from './_services/google-authentification/google-authentification.service';


let YOUR_GTM_ID = 'GTM-NRKHDTF';//Prod GTM id uses UA-98423779-1 tracking id
const hostname = window.location['hostname'].toString();

if (hostname.indexOf('localhost') !== -1
  || hostname.indexOf('explore.pre') !== -1
  || hostname.indexOf('explore.dev') !== -1
  || hostname.indexOf('explorefeature') !== -1) {
  YOUR_GTM_ID = 'GTM-TMSTKR5'; //preprod/local uses UA-98423779-2 tracking id nightly GA not getting recorded
}

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS,
  Title,
  PathLocationStrategy,
  AppState,
  ProductService,
  SessionService,
  ConfigService,
  AuthService,
  UiProfileService,
  SeoService,
  TokenService,
  MicrosoftAuthService,
  TermService,
  AuthGuardService,
  ErrorGuard,
  AuthCheckGuardService,
  QueryString,
  ProductCheck,
  ProductConfig,
  TopicService,
  GoogleAnalyticsService,
  BootstrapProduct,
  FaviconsService,
  UpdatePathGuardService,
  CleverService,
  GoogleAuthentificationService,
  ShibbolethService
];

type StoreType = {
  state: InternalStateType,
  restoreInputValues: () => void,
  disposeOldHosts: () => void
};

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    SiteFooterComponent,
    SiteBrandingComponent,
    BarcodeComponent,
    LoginComponent,
    UserPassComponent,
    InstitutionComponent,
    FindInstitutionComponent,
    GoogleLoginComponent,
    NoContentComponent,
    AppDownComponent,
    SsoComponent,
    UpdatePathComponent,
    ClickOutsideDirective,
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ModalModule,
    RouterModule.forRoot(ROUTES, { preloadingStrategy: PreloadAllModules }),
    SharedModule.forRoot(),
    SiteHeaderModule,
    NotificationModule,
    SharedModule
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    environment.ENV_PROVIDERS,
    APP_PROVIDERS,
    {
      provide: FaviconsService,
      useClass: BrowserFavicons
    },
    {
      provide: 'googleTagManagerId',
      useValue: YOUR_GTM_ID
    },
    {
      provide: BROWSER_FAVICONS_CONFIG,
      useValue: {
        icons: {
          elibrary: {
            icons: [
              { rel: 'apple-touch-icon', sizes: '57x57',    href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '60x60',    href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '72x72',    href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '76x76',    href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '114x114',  href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '120x120',  href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '144x144',  href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '152x152',  href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '180x180',  href: '/assets/icon/elibrary/apple-touch-icon.png' },
              { rel: 'icon',             sizes: '192x192',  type: 'image/png',  href: '/assets/icon/elibrary/android-chrome-192x192.png' },
              { rel: 'icon',             sizes: '256x256',  type: 'image/png',  href: '/assets/icon/elibrary/android-chrome-256x256.png' },
              { rel: 'icon',             sizes: '16x16',    type: 'image/png',  href: '/assets/icon/elibrary/favicon-16x16.png' },
              { rel: 'icon',             sizes: '32x32',    type: 'image/png',  href: '/assets/icon/elibrary/favicon-32x32.png' },
              { rel: 'icon',             sizes: '96x96',    type: 'image/png',  href: '/assets/icon/elibrary/favicon-32x32.png' }
            ]
          },
          proquest: {
            icons: [
              { rel: 'icon',             sizes: '16x16',    type: 'image/x-icon',  href: '/assets/icon/proquest/generic-favicon.ico' },
              { rel: 'icon',             sizes: '32x32',    type: 'image/x-icon',  href: '/assets/icon/elibrary/generic-favicon.ico' },
              { rel: 'icon',             sizes: '96x96',    type: 'image/x-icon',  href: '/assets/icon/elibrary/generic-favicon.ico' }
            ],
            isDefault: true
          },
          sirsissuesresearcher: {
            icons: [
              { rel: 'apple-touch-icon', sizes: '57x57',    href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '60x60',    href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '72x72',    href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '76x76',    href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '114x114',  href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '120x120',  href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '144x144',  href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '152x152',  href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '180x180',  href: '/assets/icon/researcher/apple-touch-icon.png' },
              { rel: 'icon',             sizes: '192x192',  type: 'image/png',  href: '/assets/icon/researcher/android-chrome-192x192.png' },
              { rel: 'icon',             sizes: '256x256',  type: 'image/png',  href: '/assets/icon/researcher/android-chrome-384x384.png' },
              { rel: 'icon',             sizes: '16x16',    type: 'image/png',  href: '/assets/icon/researcher/favicon-16x16.png' },
              { rel: 'icon',             sizes: '32x32',    type: 'image/png',  href: '/assets/icon/researcher/favicon-32x32.png' },
              { rel: 'icon',             sizes: '96x96',    type: 'image/png',  href: '/assets/icon/researcher/favicon-32x32.png' }
            ]
          },
          sirsdiscoverer: {
            icons: [
              { rel: 'apple-touch-icon', sizes: '57x57',    href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '60x60',    href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '72x72',    href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '76x76',    href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '114x114',  href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '120x120',  href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '144x144',  href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '152x152',  href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'apple-touch-icon', sizes: '180x180',  href: '/assets/icon/discoverer/apple-touch-icon.png' },
              { rel: 'icon',             sizes: '192x192',  type: 'image/png',  href: '/assets/icon/discoverer/android-chrome-192x192.png' },
              { rel: 'icon',             sizes: '256x256',  type: 'image/png',  href: '/assets/icon/discoverer/android-chrome-256x256.png' },
              { rel: 'icon',             sizes: '16x16',    type: 'image/png',  href: '/assets/icon/discoverer/favicon-16x16.png' },
              { rel: 'icon',             sizes: '32x32',    type: 'image/png',  href: '/assets/icon/discoverer/favicon-32x32.png' },
              { rel: 'icon',             sizes: '96x96',    type: 'image/png',  href: '/assets/icon/discoverer/favicon-32x32.png' }
            ]
          }
        }
      }
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => {
        return config.load(window.location.pathname);
      },
      deps: [ ConfigService, AppState, HttpClient, BootstrapProduct],
      multi: true
    }
  ]
})
export class AppModule {
  constructor (
    private _location: Location,
    private _urlService:URLService,
  ) {
    // checks to see if the app url path is valid format and redirects the app to the 404 page if it is not
    const uriPath = window.location.pathname + window.location.search;
    this._urlService.setSharedURL(window.location.search);
    try {
      decodeURIComponent(uriPath);
    }
    catch(e) {
      let uri = window.location.protocol + '//' + window.location.hostname;
      const profile = Cookie.get('profile');

      if (window.location.port) {
        uri = uri + ':' + window.location.port;
      }

      if (uri.indexOf('/' + profile) === -1) {
        uri = uri + '/' + profile;
      }

      // default location for the redirect uri is the root of the app. Could add a redirect uri page but it would only
      // have a loader graphic becuase all navigation is handled by the responses from api calls
      uri = uri + '/404';

      const uriData = uri.split('?');
      this._location.go(uriData[0], uriData.length == 2 ? uriData[1] : '');
    }
  }
}
